import { action, store } from '~/common/mobx.decorator'
import { fireStore } from '~/stores'
import { PROVIDER_NAME } from '~/common/constants'
import { observable } from 'mobx'

export const ERROR_TYPES = {
	locked_access: 'locked_access',
	temp_password_expired: 'temp_password_expired',
}
@store()
class LinkedInApplyStore {
	@observable phoneVerifyAt = null
	@observable changePasswordRequired = false
	@observable workerID = ''
	@observable authTokenTemp = ''
	@observable job = null

	@action
	loginLinkedIn = async (data, history) => {
		const providerData = {
			email: data?.email,
			clientId: data?.clientId,
			workerSocialFirstName: data?.workerSocialFirstName,
			workerSocialLastName: data?.workerSocialLastName,
			providerName: data?.providerName,
			clientToken: data?.clientToken,
			code: data?.code,
			redirectUri: data?.redirectUri,
		}
		return fireStore.signInWithOauth(history, providerData)
	}

	@action
	handleSuccess = async (data, history) => {
		if (data) {
			this.loginLinkedIn(
				{
					clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
					providerName: PROVIDER_NAME.linkedin,
					code: data,
					redirectUri: `${window.location.origin}/linkedin`,
				},
				history
			)
		}
	}

	@action
	setJob = async (job) => {
		this.job = job
	}
}

export const linkedInApplyStore = new LinkedInApplyStore()
