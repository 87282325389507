import { eventClient, events } from '@opus/web.core.lib.event-tracking'
import axios from 'axios'
import { isEmpty, omit } from 'lodash'
import { apolloClient, REFRESH_WORKER_TOKEN, SIGN_OUT_WORKER_MUTATION, VERIFY_EXISTED_EMAIL } from '~/common/apollo'
import { COLECTIONS, EVENTS, ONE_DAY, PATHS } from '~/common/constants'
import { captureException, RECAPTCHA_CLIENT_KEY } from '~/common/helpers'
import { action, computed, event, observable, persist, store } from '~/common/mobx.decorator'
import { notifyStore } from './notify.store'
import { fireStore, routerStore } from '~/stores'
import { careFindJobStore } from '~/features/care-find-job/care-find-job.store'
import { getUserTrackingBasicInfor } from '~/common/tracking/event-client.tracking'

import { notificationStore } from '~/features/notifications/notification.store'
import i18next from 'i18next'

const matchAnonymousEvents = async () => {
	// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
	try {
		const anonymousEvents = await window.host.getUserEvents()
		const anonymousId = await window.host.getId()

		const searchEvents = anonymousEvents
			.filter((event) => event.name === 'ANONYMOUS_SEARCH')
			.map(
				(event) =>
					new events.AnonymousSearchSuccessEvent({
						...event.data,
						metadata: {
							ip: event.ip,
							user_agent: event.userAgent,
							anonymous_id: anonymousId,
						},
					})
			)

		searchEvents.forEach((event) => eventClient.logEvent(event))
		await window.host.archiveUserEvents()
		// IN CASE: You want to clean up all archived events
		// Please call: window.host.cleanUserEvents()
	} catch (error) {
		console.debug(error)
	}
}

class ProfileItem {
	@persist @observable id
	@persist @observable firstName
	@persist @observable lastName
	@persist @observable workAuthorizedAt
	@persist @observable email
}

const DEFAULT_TOKEN_EXPIRE_IN = 30 * 60 * 1000 // calculate in miliseconds

@store({ persist: true })
class AuthStoreCandidate {
	@persist @observable token = ''
	@persist @observable refreshToken = ''
	@persist @observable maintenanceMessage = ''
	@persist @observable expiresAt = 0
	@persist @observable expiresIn = 0
	@persist @observable tokenType = ''
	@persist('object', ProfileItem) @observable profile
	@persist @observable isExistAccount = false
	@persist @observable notificationChannel = ''
	@observable currentJob = null
	@observable isAnonymousLogin = false
	@observable showDialogOtp = false
	@observable phoneNumber = ''
	@observable showChangePasswordDialog = false
	@observable phoneVerifyAt = null
	@observable showResetPassLockAccount = false
	@observable showResetPassLockContent = {}
	@observable ready = false

	@computed
	get recruiter() {
		return this.profile?.recruiter
	}

	@computed
	get authorization() {
		return !this.token || Date.now() > this.expiresAt ? '' : this.token
	}

	@computed
	get features() {
		return [
			'home',
			'completion_homepage',
			'profile',
			// 'timecards',
			'popular-cities',
			'popular-cities-detail',
			'find-job',
			'filter-job',
			'edit-filter-job',
			'job-results',
			'list-jobs',
			'urgent-needs',
			'global-search',
			'trending-jobs',
			'recommended-jobs',
			'my-jobs',
			'my-badges',
			'credentials',
			'jobs',
			'referral',
			'change-password',
			'update-phone',
			'settings',
			'last-viewed',
			'last-searches',
			'contact-us',
			'bookmark',
			'job-alerts',
			'search',
			'notifications',
			'privacy-policy',
			'verify-credential',
			'available-perdiem-shift',
		]
	}
	@computed
	get id() {
		return this.authorization && this.profile?.id
	}

	@computed
	get isLogin() {
		return this.authorization !== ''
	}

	@computed
	get firstName() {
		return this.profile?.firstName
	}

	@computed
	get workerEmail() {
		return this.profile?.email
	}

	@computed
	get lastName() {
		return this.profile?.lastName
	}

	@computed
	get isExistAccountData() {
		return this.isExistAccount
	}

	@computed
	get fullName() {
		return [this.firstName, this.lastName].join(' ').trim()
	}

	@computed
	get isAnonymous() {
		return this.isAnonymousLogin || this.authorization === ''
	}

	@computed
	get activated() {
		return !!this.profile?.workAuthorizedAt
	}

	@action
	setWorkAuthorized = (workAuthorizedAt) => {
		this.profile = { ...this.profile, workAuthorizedAt }
	}

	@computed
	get noJobMatches() {
		return this.profile?.noJobMatches
	}

	@computed
	get workerAvatar() {
		return this.profile?.avatar?.thumbnails?.s_100x100
	}

	@computed
	get hasCurrentJob() {
		return !isEmpty(this.currentJob)
	}

	@action
	setMaintenanceMessage = (message) => {
		this.maintenanceMessage = message
	}

	@action
	changeActiveTabIndex = (tabIndex) => {
		if (this.activeTabIndex === tabIndex) {
			return
		}

		this.prevActiveTabIndex = -1
		this.activeTabIndex = tabIndex
	}

	@action
	changeProfile = async (profile, notificationChannel) => {
		if (this.profile && profile && this.profile?.id === profile?.id && profile.workAuthorized === true && this.activated !== !!profile?.workAuthorizedAt) {
			eventClient.logEvent(new events.OnboardingWorkerAuthorizedSuccessEvent())
		}

		this.profile = profile
		if (notificationChannel) this.notificationChannel = notificationChannel
		if (profile) {
			eventClient.setUserId(profile.id)
			eventClient.setUserProperties({
				id: profile.id,
				company_id: process.env.REACT_APP_COMPANY_ID,
				work_authorized: !!profile.workAuthorizedAt,
			})
			getUserTrackingBasicInfor().then((response) => {
				eventClient.setBasicInfo({
					...response,
					userProperties: {
						id: profile.id,
						company_id: process.env.REACT_APP_COMPANY_ID,
						work_authorized: !!profile.workAuthorizedAt,
					},
				})
			})
			// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
			await matchAnonymousEvents()
		}
		notifyStore.sendInfo()

		// Update LiveChat Info
		if (window.LC_API && typeof window.LC_API.set_visitor_name === 'function') {
			window.LC_API.set_visitor_name([profile?.firstName, profile?.lastName].join(' ').trim())
			window.LC_API.set_visitor_email(profile?.email)
		}
	}

	@action
	handleAuthAnonymous(value) {
		return (this.isAnonymousLogin = value)
	}

	@action
	changeToken = async (authToken = {}, duration = ONE_DAY * 30) => {
		const { accessToken: token, refreshToken, expiresIn, tokenType } = authToken
		this.tokenType = tokenType
		this.token = token
		this.refreshToken = refreshToken
		this.expiresIn = (expiresIn ?? DEFAULT_TOKEN_EXPIRE_IN) * 1000 - 30 * 1000 // expiresIn unit is seconds

		this.expiresAt = Date.now() + this.expiresIn

		if (token) {
			axios.defaults.headers.common['Authorization'] = `${tokenType === 'bearer' ? 'Bearer' : ''} ${token}`
		} else {
			delete axios.defaults.headers.common['Authorization']
		}
		if (!token) {
			notifyStore.signal.push(() => {
				notifyStore.signal.removeExternalUserId()
			})
		}
	}

	@action
	refreshAuthToken = async () => {
		if (this.refreshToken) {
			try {
				const variables = { refreshToken: this.refreshToken }
				const {
					data: {
						refreshToken: { authToken },
					},
				} = await apolloClient.mutate({
					mutation: REFRESH_WORKER_TOKEN,
					variables,
					context: {
						clientName: 'public',
					},
				})

				await this.changeToken(authToken)

				return authToken
			} catch (error) {
				return Promise.resolve({})
			}
		} else {
			return Promise.resolve({})
		}
	}

	@event(EVENTS.authStore.logout)
	async logout(shouldMutateApi = true, shouldShowDialog = true, redirectLink = PATHS.common.authorization) {
		const handleOk = async () => {
			if (shouldMutateApi) {
				await apolloClient
					.mutate({
						mutation: SIGN_OUT_WORKER_MUTATION,
					})
					.catch((error) => {
						console.log('🚀 ~ file: auth.store.js:283 ~ AuthStoreCandidate ~ handleOk ~ error:', error)
					})
			}

			await this.changeToken('')
			await eventClient.setUserId(null)
			await authStore.handleAuthAnonymous(false)
			await authStore.resetPersist()
			await careFindJobStore.resetFilterValue()
			await eventClient.setUserProperties(null)
			await careFindJobStore.setInitSearchListList()
			await notificationStore.clearNotifications()
			notifyStore.unInitOneSignal()
			await routerStore.goPage(redirectLink)
			// window.location.reload()
			await getUserTrackingBasicInfor().then((response) => {
				eventClient.setBasicInfo(omit(response, ['userProperties']))
			})
		}

		if (shouldShowDialog) {
			void notifyStore.open(
				"Please note that signing out will sign you out of your current session, and you'll need to sign in again to access your account.",
				{
					title: 'Are you sure you want to sign out of your account?',
					okText: 'Sign out',
					onOk: (key) => {
						handleOk()
						notifyStore.closeDialog(key)
						notifyStore.success('You signed out successfully')
					},
					showDialog: true,
					onCancel: (key) => {
						notifyStore.closeDialog(key)
					},
				}
			)
		} else {
			void handleOk()
		}
	}

	@action
	setLastJobId = async (workerId, jobId, jobTitle) => {
		if (!isEmpty(workerId)) {
			const doc = fireStore.db.collection(COLECTIONS.last_job_id).doc(workerId)
			await doc.set({ jobId, jobTitle })
		}
	}

	@action
	handleSetShowDialogOtp = (value) => {
		this.showDialogOtp = value
	}

	@action
	handleSetPhoneNumber = (value) => {
		this.phoneNumber = value
	}

	@action
	setShowChangePasswordDialog = (value) => {
		this.showChangePasswordDialog = value
	}

	@action
	setPhoneVerifyAt = (value) => {
		this.phoneVerifyAt = value
	}
	@action
	setShowResetPassLockAccount = (value) => {
		this.showResetPassLockAccount = value
	}
	@action
	setShowResetPassLockContent = (value) => {
		this.showResetPassLockContent = value
	}
	@action
	resetPersist = () => {
		this.expiresAt = 0
		this.profile = null
		this.isExistAccount = false
		this.notificationChannel = ''
		this.currentJob = null
		this.phoneVerifiedAt = null
	}

	@action
	verifyExistEmail = async (variables) => {
		authStore.resetPersist()
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'signInWorker',
					})
					let updatedVariables = {
						recaptchaToken: token,
						...variables,
					}
					const {
						data: { verifyExistedEmail },
					} = await apolloClient.mutate({
						mutation: VERIFY_EXISTED_EMAIL,
						variables: updatedVariables,
						context: {
							clientName: 'public',
						},
					})

					const { existed, existedInAtsMessage } = verifyExistedEmail
					this.isExistAccount = existed

					if (!!existedInAtsMessage) {
						authStore.openEmailOnATSDialog()
					} else if (existed) {
						authStore.openEmailTakenDialog()
					}

					resolve(verifyExistedEmail)
				} catch (error) {
					captureException('Signin', error)
					reject(error.message)
				}
			})
		})
	}

	openEmailOnATSDialog = () => {
		return notifyStore.open(i18next.t('ALREADY_HAVE_PROFILE_CONTENT'), {
			showDialog: true,
			showButtonClose: true,
			title: i18next.t('ALREADY_HAVE_PROFILE_TITLE'),
			okText: i18next.t('I_GOT_IT'),
			onOk: (key) => {
				notifyStore.closeDialog(key)
			},
			containerStyle: `max-width:396px !important`,
			titleStyle: `width: 70%`,
		})
	}

	openEmailTakenDialog = () => {
		return notifyStore.error(i18next.t('TRY_SIGN_IN'), {
			showDialog: true,
			title: i18next.t('EMAIL_EXIST'),
			okText: i18next.t('SIGN_IN_NOW'),
			onOk: (key) => {
				routerStore.goPage(PATHS.common.signin)
				notifyStore.closeDialog(key)
			},
			onCancel: (key) => notifyStore.closeDialog(key),
		})
	}
}

export const authStore = new AuthStoreCandidate()
